// extracted by mini-css-extract-plugin
export var headline__container = "index-module--headline__container--3yzpY";
export var paragraph = "index-module--paragraph--1CvPR";
export var filter = "index-module--filter--6fbv7";
export var card__title = "index-module--card__title--2CrIM";
export var card__paragraph = "index-module--card__paragraph--KDT6F";
export var card__image = "index-module--card__image--2HX_t";
export var card___image_img = "index-module--card___image_img--3UMg5";
export var card__button = "index-module--card__button--1m8RR";
export var dropDowns__container = "index-module--dropDowns__container--396nZ";
export var dropDown = "index-module--dropDown--1q4mQ";
export var cards__container = "index-module--cards__container--1ZHdQ";