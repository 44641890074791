import * as React from 'react';
import CustomButton from '../CustomButton/CustomButton';
// @ts-ignore
import * as styles from './VotingBarFinished.module.css';
import {apiPostAuthenticated} from '../../api/api';
import {navigate} from 'gatsby';

const VotingBarFinished = () => {
    return <div className={styles.container}>
        Sie haben alle Bewertungen abgeschlossen.
        Klicken Sie hier um die Bewertungen final abzusenden:
        <CustomButton
            onClick={() => {
                (async() => {
                    const result = await apiPostAuthenticated('/jury/finish');
                    if (result.success) {
                        await navigate('/jury/finished');
                    } else {
                        // TODO: handle error
                    }
                })();
            }}
            label="Final abschicken"/>
    </div>;
};

export default VotingBarFinished;
