// extracted by mini-css-extract-plugin
export var card = "VotingCard-module--card--Q1YNT";
export var cardOpen = "VotingCard-module--cardOpen--1Q4gp";
export var bottomContainer = "VotingCard-module--bottomContainer--28u4B";
export var isOpen = "VotingCard-module--isOpen--16d8y";
export var label = "VotingCard-module--label--2pEpl";
export var labelHover = "VotingCard-module--labelHover--1ectv";
export var labelIcon = "VotingCard-module--labelIcon--3D53G";
export var labelIconOpen = "VotingCard-module--labelIconOpen--3cqYh";
export var table = "VotingCard-module--table--knZnp";
export var tableLabel = "VotingCard-module--tableLabel--qnPnD";