import * as React from 'react';
import DefaultPage from '../../components/layouts/DefaultPage';
// import Select from '../../components/formElements/Select/Select';
import CustomButton from '../../components/CustomButton/CustomButton';
import Headline from '../../components/Headline/Headline';
// @ts-ignore
import * as styles from './index.module.css';
import {
    useEffect, useState
} from 'react';
import {
    fetchTopApplications
} from '../../api/application';
import {
    apiDomain, apiGetAuthenticated
} from '../../api/api';
import {connect} from 'react-redux';
import {votingCategories} from '../../components/VoteBar/VoteBar';
import VotingCard from '../../components/Card/VotingCard';
import VotingBarFinished from '../../components/VotingBarFinished/VotingBarFinished';
import {navigate} from 'gatsby';
import {getAuthUser} from '../../helpers/user';

const index = ({juryVotings, setJuryVotings,}) => {
    const [topApplications, setTopApplications,] = useState([]);
    const [filtered, setFiltered,] = useState([1,2,3,]);

    const user = getAuthUser();
    useEffect(() => {
        if (!user) {
            navigate('/jury/login');
        }else if (!user.accepted_welcome_privacy) {
            navigate('/jury/welcome');
        }
    }, [user,]);

    useEffect(() => {
        (async () => {
            setJuryVotings({
                juryVotings: (await apiGetAuthenticated('/jury/votes')).votings,
            });
        })();
    }, []);

    useEffect(() => {
        if (localStorage.getItem('@focus//jury-filter')) {
            setFiltered(JSON.parse(localStorage.getItem('@focus//jury-filter')));
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('@focus//jury-filter', JSON.stringify(filtered));
    }, [filtered,]);

    useEffect(
        () => {
            (async () => {
                const result = await fetchTopApplications();
                if (result.success) {
                    const topApplications = result.applications.map(application => {
                        return {
                            ...application,
                            votings: juryVotings.filter(v => {
                                return v.applicationUuid === application.uuid;
                            }),
                        };
                    });
                    setTopApplications(topApplications);
                }
            })();
        },
        [juryVotings,]
    );

    // store last scroll position
    const [usedLastPosition, setUsedLastPosition,] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('@focus//jury.index.scrollposition')) {
            window.scrollTo(0, parseInt(localStorage.getItem('@focus//jury.index.scrollposition'), 10));
        }
        setTimeout(() => {
            setUsedLastPosition(true);
        }, 2000);
    }, [topApplications,]);

    useEffect(() => {
        if (!usedLastPosition || !topApplications || 0 === topApplications.length) {
            return;
        }
        let ticking = false;
        const updateScrollPosition = async () => {
            localStorage.setItem('@focus//jury.index.scrollposition', String(window.pageYOffset));
            ticking = false;
        };
        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollPosition);
                ticking = true;
            }
        };
        window.addEventListener('scroll', onScroll);
        return () => {
            return window.removeEventListener('scroll', onScroll);
        };
    }, [usedLastPosition, topApplications,]);

    const numberOfFinsishedVotings = topApplications.filter(a => {
        return a.votings.length === votingCategories.length;
    }).length;
    return <>
        <DefaultPage phase={process.env.GATSBY_PHASE}>
            <div className={styles.headline__container}>
                <Headline headline={`Ihr Voting Top ${topApplications.length} Innovationen`}/>
            </div>

            {/*Drop Downs Container */}
            {/*<div className={styles.dropDowns__container}>*/}
            {/*    <div className={styles.dropDown}>*/}
            {/*        <label>Sortieren</label>*/}
            {/*        <input type="text"/>*/}
            {/*    </div>*/}

            {/*    <div className={styles.dropDown}>*/}
            {/*        <label>Filtern</label>*/}
            {/*        <input type="text"/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<Select*/}
            {/*    label="A-Z"*/}
            {/*    name="TEST"*/}
            {/*    options={[{*/}
            {/*        value: '123', label: 'hello',*/}
            {/*    }, {*/}
            {/*        value: '456', label: 'hello2',*/}
            {/*    },]}*/}
            {/*/>*/}
            <div className={`grid-2--mobile grid-8--tablet grid-12--desktop ${styles.filter}`}>
                <p className={styles.paragraph}><span>{numberOfFinsishedVotings}</span>
                    &nbsp;von <span>{topApplications.length}</span> vollständig bewertet
                </p>

                <div>
                    <label>
                        Bewertet
                        <input
                            type="checkbox"
                            checked={filtered.includes(1)}
                            onChange={() => {
                                if (!filtered.includes(1)) {
                                    setFiltered([...filtered, 1,]);
                                } else {
                                    setFiltered([...filtered.filter(f => {
                                        return 1 !== f;
                                    }),]);
                                }
                            }}
                        />
                    </label>
                    <label>
                        Bewertung angefangen
                        <input
                            type="checkbox"
                            checked={filtered.includes(2)}
                            onChange={() => {
                                if (!filtered.includes(2)) {
                                    setFiltered([...filtered, 2,]);
                                } else {
                                    setFiltered([...filtered.filter(f => {
                                        return 2 !== f;
                                    }),]);
                                }
                            }}
                        />
                    </label>
                    <label>
                        Noch nicht bewertet
                        <input
                            type="checkbox"
                            checked={filtered.includes(3)}
                            onChange={() => {
                                if (!filtered.includes(3)) {
                                    setFiltered([...filtered, 3,]);
                                }    else {
                                    setFiltered([...filtered.filter(f => {
                                        return 3 !== f;
                                    }),]);
                                }
                            }}
                        />
                    </label>
                </div>
            </div>

            <div className={`flex grid-8--tablet grid-12--desktop ${styles.cards__container}`}>
                {
                    topApplications
                        .filter(application => {
                            const votings = application.votings;
                            let status = 3;
                            if (0 < votings.length && votings.length < votingCategories.length) {
                                status = 2;
                            }
                            if (votings.length === votingCategories.length) {
                                status = 1;
                            }
                            return filtered.includes(status);
                        })
                        .map((
                            application, index
                        ) => {
                            return <VotingCard
                                key={`card${index}`}
                                votings={application.votings}>
                                <h2 className={styles.card__title}>{application.data.innovationName}</h2>
                                <p className={styles.card__paragraph}>
                                    {application.data.innovationShortDescription}
                                </p>
                                <div className={styles.card__image}>
                                    {
                                        application.data.documentsLogo &&
                                        <img
                                            className={styles.card___image_img}
                                            src={`${apiDomain}/image/400x300?path=${application.data.documentsLogo.path}`}
                                            alt=""/>
                                    }
                                </div>
                                <CustomButton
                                    className={styles.card__button}
                                    isLink
                                    to={`/jury/detail#${application.uuid}`}
                                    style="button"
                                    label="Ansehen & Voten"/>

                            </VotingCard>;
                        })
                }
            </div>

        </DefaultPage>;
        {
            numberOfFinsishedVotings === topApplications.length && 0 < topApplications.length &&
            <VotingBarFinished/>
        }
    </>;
};

const mapStateToProps = ({
    juryVotings,
}) => {
    return {
        juryVotings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setJuryVotings: ({
            juryVotings,
        }) => {
            return dispatch({
                type: 'SET_JURY_VOTINGS',
                payload: {
                    juryVotings,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(index);

