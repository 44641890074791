import * as React from 'react';
import Card from './Card';
import {votingCategories} from '../VoteBar/VoteBar';
import {classNames} from '../../helpers/classes';
import * as styles from './VotingCard.module.css';
import {useState} from 'react';

const VotingCard = ({children, votings,}) => {
    const [isOpen, setIsOpen,] = useState(false);

    let label = 'Noch nicht bewertet';
    if (0 < votings.length && votings.length < votingCategories.length) {
        label = 'Bewertung angefangen';
    }
    if (votings.length === votingCategories.length) {
        label = `Bewertet mit ${votings.map(v => {
            return v.voting;
        }).reduce((a, b) => {
            return a + b;
        }, 0)} Punkten`;
    }

    return <Card className={classNames({
        [styles.card]: true,
        [styles.cardOpen]: isOpen,
    })}>
        {children}
        <div
            onClick={() => {
                setIsOpen(!isOpen);
            }}
            className={classNames({
                [styles.bottomContainer]: true,
                [styles.isOpen]: isOpen,
            })}>

            <div>
                <div
                    className={classNames({
                        [styles.label]: true,
                        [styles.labelHover]: !isOpen,
                    })}
                >
                    <span>{label}</span>
                    <svg
                        className={classNames({
                            [styles.labelIcon]: true,
                            [styles.labelIconOpen]: isOpen,
                        })}
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.874"
                        height="9.802"
                        viewBox="0 0 15.874 9.802">
                        <path
                            id="Path_534"
                            data-name="Path 534"
                            d="M7.865,8.59l6.072,6.059L20.009,8.59l1.865,1.865-7.937,7.937L6,10.455Z"
                            transform="translate(-6 -8.59)"
                            fill="#1D1D1B"/>
                    </svg>
                </div>
                {isOpen && <table className={styles.table}>
                    <tbody>
                        {votingCategories.map(c => {
                            return <tr>
                                <td className={styles.tableLabel}>
                                    {c}
                                </td>
                                <td>
                                    {
                                        votings.filter(v => {
                                            return v.category === c;
                                        }).length ?
                                            votings.filter(v => {
                                                return v.category === c;
                                            })[0].voting : '-'
                                    }  / 10
                                </td>
                            </tr>;
                        })}
                    </tbody>
                </table>}
            </div>
        </div>
    </Card>;
};

export default VotingCard;
